@tailwind base;
@tailwind components;
@tailwind utilities;

.general_button_style {
  @apply bg-gradient-to-r from-cyan-500 to-blue-500 rounded-full p-3 w-36 font-bold text-white;
}

.highscores-section {
  width: 250px;
  padding-left: 20px;
  border-left: 1px solid #ddd;
}

.highscores {
  width: 100%;
  border-collapse: collapse;
}

.highscores th, .highscores td {
  border: 1px solid #ccc;
  padding: 8px;
}